import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { TableListHead, TableListToolbar } from "src/sections/@dashboard/table";
import orderInstance from "src/axios/orderInstance";
import { OrderFilterMenu } from "src/helpers/MenuItems";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "question", label: "Question", alignRight: false },
  { id: "book", label: "Book", alignRight: false },
  { id: "viewcount", label: "View Count", alignRight: false },
  { id: "downloadcount", label: "Download Count", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
];

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export default function Order() {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [orderCount, setOrderCount] = useState(1);
  const [filter, setFilter] = useState({
    key: "",
    filterBy: "",
  });

  const onFilterChange = (filterKey, filterValue) => {
    setFilter({
      key: filterKey,
      filterBy: filterValue,
    });
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading(true);
        const orderResponse = await orderInstance.get("/", {
          params: {
            page: pageNumber,
            limit: limit,
            [filter.key]: filter.filterBy,
          },
        });
        setLoading(false);
        setOrderData(orderResponse.data.ordersData);
        setOrderCount(orderResponse.data.totalOrders);
      } catch (error) {
        setLoading(false);
      }
    };

    getOrders();

    return {};
  }, [pageNumber, limit, filter.key, filter.filterBy]);

  return (
    <Page title="Category">
      <Container>
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={80} />
          </LoaderWrapper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
        </Stack>
        {!loading && (
          <>
            <Card>
              <TableListToolbar
                onFilterChange={onFilterChange}
                currentFilter={filter}
                filterList={OrderFilterMenu}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {orderData.map((row) => {
                        const {
                          _id,
                          amount,
                          downloadCount,
                          viewCount,
                          status,
                          solutionId,
                          customerId,
                          orderToken,
                        } = row;
                        return (
                          <TableRow
                            onClick={(e) => {
                              navigate(`/dashboard/order-detail/${orderToken}`);
                            }}
                            hover
                            key={_id}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              component="th"
                              size="small"
                              scope="row"
                              style={{
                                maxWidth: 150,
                              }}
                              padding="normal"
                            >
                              {customerId?.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              size="large"
                              scope="row"
                              style={{
                                maxWidth: 150,
                              }}
                              padding="normal"
                            >
                              {customerId?.email}
                            </TableCell>
                            <TableCell
                              component="th"
                              size="large"
                              scope="row"
                              style={{
                                maxWidth: 150,
                              }}
                              padding="normal"
                            >
                              {solutionId?.title}
                            </TableCell>
                            <TableCell align="left">
                              {solutionId?.book?.title}
                            </TableCell>
                            <TableCell align="left">{viewCount}</TableCell>
                            <TableCell align="left">{downloadCount}</TableCell>
                            <TableCell align="left">{status}</TableCell>
                            <TableCell align="left">{amount}$</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
            <PaginationWrapper>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 500]}
                component="div"
                count={orderCount}
                rowsPerPage={limit}
                page={pageNumber - 1}
                onPageChange={(event, value) => {
                  setPageNumber(value + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPageNumber(1);
                }}
              />
            </PaginationWrapper>
          </>
        )}
      </Container>
    </Page>
  );
}
