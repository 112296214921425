import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Switch,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { TableListHead, TableListToolbar } from "src/sections/@dashboard/table";
import solutionInstance from "src/axios/solutionInstance";
import DetailModal from "src/components/DetailModal/orderDetail";
import { SolutionFilterMenu } from "src/helpers/MenuItems";
import { toast } from "react-toastify";
import { SUPER_ADMIN, ADMIN, SEARCHER } from "src/utils/Roles";

let TABLE_HEAD = [
  { id: "no", label: "No.", alignRight: false, maxWidth: 50 },
  { id: "question", label: "Title", alignRight: false, maxWidth: 50 },
  { id: "bookname", label: "Book Name", alignRight: false, maxWidth: 50 },
  { id: "orderCount", label: "Orders", alignRight: false, maxWidth: 50 },
  { id: "category", label: "Category", alignRight: false, maxWidth: 50 },
  { id: "price", label: "Price", alignRight: false, maxWidth: 50 },
  { id: "views", label: "Views", alignRight: false, maxWidth: 50 },
  { id: "action", label: "Action", alignRight: false, maxWidth: 50 },
];

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  ":hover": {
    color: "#36b693",
  },
}));

let timeout = null;
export default function Solution() {
  const { role: userRole } = useSelector((state) => state.auth);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [solutionData, setSolutionData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [orderCount, setOrderCount] = useState(1);
  const [solutionDetail, setSolutionDetail] = useState("");
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState({
    key: "",
    filterBy: "",
  });
  const navigate = useNavigate();
  const [searchLoading, setSearchLoading] = useState(false);
  const [statusCount, setStatusCount] = useState({
    activeSolutions: 0,
    inActiveSolutions: 0,
  });

  if (userRole === SEARCHER) {
    TABLE_HEAD = [...TABLE_HEAD.filter((value) => value.id !== "action")];
  }

  const onFilterChange = (filterKey, filterValue) => {
    setFilter({
      key: filterKey,
      filterBy: filterValue,
    });
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading(true);
        const solutionResponse = await solutionInstance.get("/", {
          params: {
            page: pageNumber,
            limit: limit,
            [filter.key]: filter.filterBy,
          },
        });
        setLoading(false);
        setSolutionData(solutionResponse.data.data);
        setStatusCount({
          activeSolutions: solutionResponse.data.activeSolutions,
          inActiveSolutions: solutionResponse.data.inActiveSolutions,
        });
        setOrderCount(solutionResponse.data.totalSolutions);
      } catch (error) {
        setLoading(false);
      }
    };
    if (!query) {
      getOrders();
    }
  }, [pageNumber, limit, filter.key, filter.filterBy, query]);

  const onSearch = (value) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      searchSolution(value);
      setQuery(value);

      if (value) {
        searchSolution(value, 1, limit);

        setPageNumber(1);
      } else {
        searchSolution(value, 1, limit);
      }
    }, 1000);
  };

  const searchSolution = async (searchedQuery, page, queryLimit) => {
    try {
      setSearchLoading(true);
      const searchSolutionResponse = await solutionInstance.get("/search", {
        params: {
          searchedQuery: searchedQuery,
          page: page,
          limit: queryLimit,
        },
      });
      setSearchLoading(false);
      setSolutionData(searchSolutionResponse.data.data);
      setOrderCount(searchSolutionResponse.data.totalSolutions);
    } catch (error) {
      setSearchLoading(false);
    }
  };

  const updateSolutionStatus = (index) => {
    const updatedSolutionData = [...solutionData];
    updatedSolutionData[index].status = !updatedSolutionData[index].status;
    setSolutionData(updatedSolutionData);
  };

  const handleStatusChange = async (e, solutionId, index) => {
    e.stopPropagation();
    try {
      updateSolutionStatus(index);

      await solutionInstance.post("/update-status", {
        solutionId: solutionId,
        status: e.target.checked,
      });
    } catch (error) {
      console.log(error.message);
      updateSolutionStatus(index);
      toast.error("Unable to update status. Try again", { autoClose: 3000 });
    }
  };

  return (
    <Page title="Category">
      <DetailModal
        isOpen={solutionDetail ? true : false}
        setOpen={() => setSolutionDetail("")}
        from="Solution"
        instance={solutionInstance}
        route={"/solution-detail"}
        query={{
          solutionId: solutionDetail,
        }}
      />
      <Container>
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={80} />
          </LoaderWrapper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Solution
          </Typography>
        </Stack>
        {!loading && (
          <>
            <Card>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                margin="1rem 1rem 0 1rem"
              >
                <Typography variant="h6">
                  Total Solutions : {orderCount}
                </Typography>
                <Typography variant="h6">
                  Active Solutions : {statusCount?.activeSolutions}
                </Typography>
                <Typography variant="h6">
                  InActive Solutions : {statusCount?.inActiveSolutions}
                </Typography>
              </Stack>
              <TableListToolbar
                currentFilter={filter}
                onFilterChange={onFilterChange}
                filterList={SolutionFilterMenu}
                showSearchBar={true}
                placeholder="Search Solutions..."
                onSearch={onSearch}
                isLoading={searchLoading}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {solutionData.map((row, index) => {
                        const {
                          _id,
                          book,
                          orderCount,
                          category,
                          title,
                          solutionNumber,
                          price,
                          views,
                          status,
                        } = row;
                        return (
                          <TableRow
                            onClick={(e) => {
                              if (
                                userRole === SUPER_ADMIN ||
                                userRole === ADMIN ||
                                userRole === SEARCHER
                              ) {
                                setSolutionDetail(_id);
                                navigate(`/dashboard/solution-detail/${_id}`);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                            hover
                            key={_id}
                          >
                            <TableCell align="left">{solutionNumber}</TableCell>
                            <TableCell
                              component="th"
                              size="small"
                              padding="normal"
                              style={{
                                maxWidth: 150,
                              }}
                            >
                              <Typography variant="body2">{title}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                  "&:hover": {
                                    color: "#36b693",
                                  },
                                }}
                              >
                                {(userRole === SUPER_ADMIN || userRole === ADMIN) ? (
                                  <StyledLink
                                    onClick={(e) => e.stopPropagation()}
                                    to={`/dashboard/book-detail/${book._id}`}
                                  >
                                    {book?.title}
                                  </StyledLink>
                                ) : (
                                  <Typography>{book?.title}</Typography>
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  orderCount !== 0 &&
                                  (userRole === SUPER_ADMIN || userRole === ADMIN)
                                ) {
                                  setSolutionDetail(_id);
                                }
                              }}
                              sx={{
                                "&:hover": {
                                  color: "#36b693",
                                },
                              }}
                              align="left"
                            >
                              {orderCount}
                            </TableCell>
                            <TableCell align="left">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                  "&:hover": {
                                    color: "#36b693",
                                  },
                                }}
                              >
                                {userRole === SUPER_ADMIN ? (
                                  <StyledLink
                                    onClick={(e) => e.stopPropagation()}
                                    to={`/dashboard/category-detail/${category._id}`}
                                  >
                                    {category.name}
                                  </StyledLink>
                                ) : (
                                  <Typography>{category.name}</Typography>
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{price}$</TableCell>
                            <TableCell align="left">{views}</TableCell>
                            {userRole === SUPER_ADMIN && (
                              <TableCell align="left">
                                <Switch
                                  checked={status}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    handleStatusChange(e, _id, index);
                                  }}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
            <PaginationWrapper>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 500]}
                component="div"
                count={orderCount}
                rowsPerPage={limit}
                page={pageNumber - 1}
                onPageChange={(event, value) => {
                  setPageNumber(value + 1);
                  if (query) {
                    searchSolution(query, value + 1, limit);
                  }
                }}
                onRowsPerPageChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPageNumber(1);
                  if (query) {
                    searchSolution(
                      query,

                      pageNumber,

                      parseInt(event.target.value, 10)
                    );
                  }
                }}
              />
            </PaginationWrapper>
          </>
        )}
      </Container>
    </Page>
  );
}
