// component
import Iconify from "../../components/Iconify";

import {
  ADMIN,
  SUPER_ADMIN,
  MANAGER,
  SEARCHER,
  FINANCE,
} from "src/utils/Roles";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/main",
    icon: getIcon("eva:pie-chart-2-fill"),
    allowedRoles: [SUPER_ADMIN, ADMIN, MANAGER, SEARCHER, FINANCE],
  },
  {
    title: "members",
    path: "/dashboard/members",
    icon: getIcon("eva:people-fill"),
    allowedRoles: [SUPER_ADMIN, ADMIN],
  },
  {
    title: "orders",
    path: "/dashboard/order",
    icon: getIcon("ant-design:ordered-list-outlined"),
    allowedRoles: [SUPER_ADMIN, ADMIN, FINANCE],
  },
  {
    title: "books",
    path: "/dashboard/book",
    icon: getIcon("eva:book-open-outline"),
    allowedRoles: [SUPER_ADMIN, ADMIN, MANAGER],
  },
  {
    title: "solutions",
    path: "/dashboard/solution",
    icon: getIcon("ep:document"),
    allowedRoles: [SUPER_ADMIN, ADMIN, SEARCHER],
  },
  {
    title: "categories",
    path: "/dashboard/category",
    icon: getIcon("bx:category"),
    allowedRoles: [SUPER_ADMIN, ADMIN, MANAGER],
  },
  {
    title: "waiting list",
    path: "/dashboard/waiting-list",
    icon: getIcon("medical-icon:i-waiting-area"),
    allowedRoles: [SUPER_ADMIN, ADMIN],
  },
  {
    title: "search logs",
    path: "/dashboard/searchlog",
    icon: getIcon("fa-solid:search-plus"),
    allowedRoles: [SUPER_ADMIN, ADMIN],
  },
  {
    title: "site settings",
    path: "/dashboard/site-setting",
    icon: getIcon("clarity:settings-solid-badged"),
    allowedRoles: [SUPER_ADMIN],
  },
];

export default navConfig;
