// material-ui
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Box,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";

//axios
import categoryInstance from "src/axios/categoryInstance";
import { getToken } from "src/store/localStorage";

//project
import ColorPicker from "src/components/color-picker/ColorPicker";
import IconPicker from "src/components/icon-picker/IconPicker";
import Editor from "src/components/TextEditor/Editor";

const LevelInfo = styled("span")(({ theme }) => ({
  fontSize: ".8rem",
  display: "inline-block",
  marginLeft: ".2rem",
}));

const AddCategoryForm = () => {
  const { categoryId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = JSON.parse(searchParams.get("isEdit"));
  const navigate = useNavigate();
  const token = getToken();
  const [category, setCategory] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [showIconPicker, setShowIconPicker] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState({
    title: "",
    parent: "N/A",
    level: "1",
    color: "#8C30F5",
    description: "",
    icon: "",
  });

  useEffect(() => {
    const getCategories = async () => {
      try {
        const categoryResponse = await categoryInstance.get("/", {
          headers: {
            "x-access-token": `${token}`,
          },
        });
        setCategory(categoryResponse.data.categories);
      } catch (error) {}
    };

    getCategories();
  }, [token]);

  useEffect(() => {
    const getCategoyDetail = async () => {
      try {
        const categoryDetailResponse = await categoryInstance.get(
          `/category-detail?categoryId=${categoryId}`,
          {
            headers: {
              "x-access-token": `${token}`,
            },
          }
        );

        const catDetail = {
          title: categoryDetailResponse.data.detail.name,
          parent: categoryDetailResponse.data.detail.parentId,
          level: categoryDetailResponse.data.detail.level,
          description: categoryDetailResponse.data.detail.description,
          color:
            categoryDetailResponse.data.detail.color === "N/A"
              ? "#000"
              : categoryDetailResponse.data.detail.color,
          icon:
            categoryDetailResponse.data.detail.icon === "N/A"
              ? ""
              : categoryDetailResponse.data.detail.icon,
        };

        setCategoryDetail(catDetail);
      } catch (err) {}
    };

    if (categoryId && isEdit === true) {
      getCategoyDetail();
    }
  }, [categoryId, token, isEdit]);

  const handleAddCategory = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    try {
      setShowIconPicker(false);
      setShowPicker(false);
      const url =
        isEdit === true ? `/update-category/${categoryId}` : "/add-category";

      const requestMethod =
        isEdit === true ? categoryInstance.put : categoryInstance.post;

      const categoryResponse = await requestMethod(
        url,
        {
          title: values.title,
          description: values.description,
          parentId: values.parent,
          level: values.level,
          color: values.color,
          icon: values.icon,
        },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      );
      if (categoryResponse.status === 200) {
        resetForm();
        navigate("/dashboard/category");
      }
    } catch (err) {}
  };

  const handleCustomChange = (parentId, setFieldValue) => {
    if (parentId === "N/A") {
      setFieldValue("level", 1);
      return;
    }
    for (let i = 0; i < category.length; i++) {
      if (category[i]._id === parentId) {
        setFieldValue("level", category[i].level + 1);
        return;
      }
    }
  };

  function sortArray(x, y) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Formik
        initialValues={{
          title: categoryDetail.title,
          parent: categoryDetail.parent,
          level: categoryDetail.level,
          color: categoryDetail.color,
          description: categoryDetail.description,
          icon: categoryDetail.icon,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(255).required("Title is required"),
          parent: Yup.string().required("Parent Category is required"),
          level: Yup.string().required("Level is required"),
          color: Yup.string().required("Color is required"),
          description: Yup.string().required("Description is required"),
          icon: Yup.string().required("Icon is required"),
        })}
        enableReinitialize={true}
        onSubmit={handleAddCategory}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid container item spacing={4} xs={12}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.title && errors.title)}
                  >
                    <InputLabel htmlFor="outlined-title">Title</InputLabel>
                    <OutlinedInput
                      id="outlined-title"
                      type="text"
                      value={values.title}
                      name="title"
                      onBlur={handleBlur}
                      onChange={(event) => handleChange(event)}
                      label="Title"
                      inputProps={{}}
                    />
                    {touched.title && errors.title && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-title"
                      >
                        {errors.title}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.parent && errors.parent)}
                  >
                    <InputLabel htmlFor="outlined-adornment-assignedto">
                      Parent Category
                    </InputLabel>
                    <Select
                      style={{ height: "60px" }}
                      name="parent"
                      id="outlined-adornment-assignedto"
                      value={values.parent}
                      onBlur={handleBlur}
                      label="Parent Category"
                      onChange={(event) => {
                        handleChange(event);
                        handleCustomChange(event.target.value, setFieldValue);
                      }}
                      inputProps={{ readOnly: isEdit }}
                    >
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                      {category &&
                        category
                          .filter((value) => value.level < 3)
                          .sort(sortArray)
                          .map((value) => {
                            return (
                              <MenuItem
                                value={value?._id}
                                key={value?._id}
                                disabled={value?.level === 1}
                              >
                                {value?.name}
                                <LevelInfo>{`(Level-${value?.level})`}</LevelInfo>
                              </MenuItem>
                            );
                          })}
                    </Select>
                    {!values.parent && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-assign"
                      >
                        {errors.parent}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item spacing={4} xs={12}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.level && errors.level)}
                  >
                    <InputLabel htmlFor="outlined-adornment-assignedto">
                      Category Level
                    </InputLabel>
                    <InputLabel htmlFor="outlined-title">
                      Category Level
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-title"
                      type="text"
                      value={values.level}
                      name="level"
                      onBlur={handleBlur}
                      onChange={(event) => handleChange(event)}
                      label="Category Level"
                      inputProps={{ readOnly: true }}
                    />

                    {!values.level && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-assign"
                      >
                        {errors.level}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item container xs={6} spacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.color && errors.color)}
                    >
                      <ColorPicker
                        color={values.color}
                        setFieldValue={setFieldValue}
                        showPicker={showPicker}
                        setShowPicker={setShowPicker}
                      />

                      {!values.color && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-assign"
                        >
                          {errors.color}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.icon && errors.icon)}
                    >
                      <IconPicker
                        setFieldValue={setFieldValue}
                        icon={values.icon}
                        showIconPicker={showIconPicker}
                        setShowIconPicker={setShowIconPicker}
                        color={values.color}
                      />
                      {!values.icon && Boolean(touched.icon) && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-assign"
                        >
                          {errors.icon}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.description && errors.description)}
                  >
                    <Editor
                      description={values.description}
                      setFieldValue={setFieldValue}
                    />
                    {!values.description && Boolean(touched.description) && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-assign"
                      >
                        {errors.description}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 2, position: "relative" }}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {isEdit ? "Update" : "Add"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddCategoryForm;
