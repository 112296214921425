// material-ui
import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, Stack, Divider } from "@mui/material";
import { styled } from "@mui/system";
import categoryInstance from "src/axios/categoryInstance";
import { useParams } from "react-router-dom";
// third party
import ReactHtmlParser from "html-react-parser";

const InfoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: 0,
  padding: "10px",
  backgroundColor: "#f7f7f7",
}));
const CategoryDetail = () => {
  const [categoryData, setCategoryData] = useState();
  const { id } = useParams();
  useEffect(() => {
    const getCategoryDetail = async () => {
      try {
        const CategorResponse = await categoryInstance.get("/category-detail", {
          params: {
            categoryId: id,
          },
        });
        setCategoryData(CategorResponse.data.detail);
      } catch (error) {
        console.log(error);
      }
    };
    getCategoryDetail();
  }, [id]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Category Detail
        </Typography>
      </Stack>
      <Grid container spacing={4}>
        <Grid container item spacing={4} xs={12}>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Name:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">
                {categoryData && categoryData.name}
              </Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Level:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">
                {categoryData && categoryData.level}
              </Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Parent:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">
                {categoryData && categoryData.parentName}
              </Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Icon:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">
                {categoryData && categoryData.icon}
              </Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={8}>
            <InfoPaper>
              <Typography variant="h5">Description:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">
                {ReactHtmlParser(categoryData && categoryData.description)}
              </Typography>
            </InfoPaper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryDetail;
