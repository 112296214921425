import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import {
  Engineering,
  Code,
  Architecture,
  Vaccines,
  LocalHospital,
  Healing,
  Masks,
  MedicationLiquid,
  Science,
  Biotech,
  Psychology,
  Percent,
  Spa,
  Functions,
  Calculate,
  Straighten,
  DesignServices,
  SquareFoot,
  PieChart,
  AddBusiness,
  Business,
  Work,
  WorkHistory,
  NextWeek,
  Analytics,
  ManageAccounts,
  DataSaverOff,
  Equalizer,
  QueryStats,
  Score,
  ScatterPlot,
  MultilineChart,
  LocalPolice,
  Gavel,
  AccountBalance,
  AssuredWorkload,
  ManageHistory,
  ManageSearch,
  PestControl,
  ContentPaste,
  Construction,
  SignLanguage,
  LocalPhone,
  SatelliteAlt,
  SmsFailed,
  ConnectWithoutContact,
  ConnectedTv,
  EnergySavingsLeaf,
  Quiz,
  Hardware,
  Foundation,
  Handyman,
  Computer,
  DeviceHub,
  Devices,
  Agriculture,
  PrecisionManufacturing,
  TireRepair,
  HomeRepairService,
  Terminal,
  AppShortcut,
  DocumentScanner,
  Savings,
  AccountBalanceWallet,
  MonetizationOn,
  Money,
  PriceCheck,
  Payment,
  PointOfSale,
  Newspaper,
  Storage,
  LocalGroceryStore,
  Store,
  MiscellaneousServices,
  Wifi,
  SupportAgent,
  Sports,
  DirectionsTransit,
  BusinessCenter,
  ScienceOutlined,
  Settings,
  SpeakerPhone,
  TranslateOutlined,
} from "@mui/icons-material";

export const Icons = [
  { name: "Engineering", Component: Engineering },
  { name: "Code", Component: Code },
  { name: "Architecture", Component: Architecture },
  { name: "Vaccines", Component: Vaccines },
  { name: "LocalHospital", Component: LocalHospital },
  { name: "Healing", Component: Healing },
  { name: "Masks", Component: Masks },
  { name: "MedicationLiquid", Component: MedicationLiquid },
  { name: "Science", Component: Science },
  { name: "Biotech", Component: Biotech },
  { name: "Psychology", Component: Psychology },
  { name: "Percent", Component: Percent },
  { name: "SpaOutlined", Component: Spa },
  { name: "Functions", Component: Functions },
  { name: "Calculate", Component: Calculate },
  { name: "Straighten", Component: Straighten },
  { name: "DesignServices", Component: DesignServices },
  { name: "Square", Component: SquareFoot },
  { name: "PieChart", Component: PieChart },
  { name: "AddBusiness", Component: AddBusiness },
  { name: "Business", Component: Business },
  { name: "Work", Component: Work },
  { name: "WorkHistory", Component: WorkHistory },
  { name: "NextWeek", Component: NextWeek },
  { name: "Analytics", Component: Analytics },
  { name: "ManageAccounts", Component: ManageAccounts },
  { name: "DataSaverOff", Component: DataSaverOff },
  { name: "Equalizer", Component: Equalizer },
  { name: "QueryStats", Component: QueryStats },
  { name: "Score", Component: Score },
  { name: "ScatterPlot", Component: ScatterPlot },
  { name: "MultilineChart", Component: MultilineChart },
  { name: "LocalPolice", Component: LocalPolice },
  { name: "Gavel", Component: Gavel },
  { name: "AccountBalance", Component: AccountBalance },
  { name: "AssuredWorkload", Component: AssuredWorkload },
  { name: "ManageHistory", Component: ManageHistory },
  { name: "ManageSearch", Component: ManageSearch },
  { name: "PestControl", Component: PestControl },
  { name: "ContentPaste", Component: ContentPaste },
  { name: "Construction", Component: Construction },
  { name: "SignLanguage", Component: SignLanguage },
  { name: "LocalPhone", Component: LocalPhone },
  { name: "SatelliteAlt", Component: SatelliteAlt },
  { name: "SmsFailed", Component: SmsFailed },
  { name: "ConnectWithoutContact", Component: ConnectWithoutContact },
  { name: "ConnectedTv", Component: ConnectedTv },
  { name: "EnergySavingsLeaf", Component: EnergySavingsLeaf },
  { name: "Quiz", Component: Quiz },
  { name: "Hardware", Component: Hardware },
  { name: "Foundation", Component: Foundation },
  { name: "Handyman", Component: Handyman },
  { name: "Computer", Component: Computer },
  { name: "DeviceHub", Component: DeviceHub },
  { name: "Devices", Component: Devices },
  { name: "Agriculture", Component: Agriculture },
  { name: "PrecisionManufacturing", Component: PrecisionManufacturing },
  { name: "TireRepair", Component: TireRepair },
  { name: "HomeRepairService", Component: HomeRepairService },
  { name: "Terminal", Component: Terminal },
  { name: "AppShortcut", Component: AppShortcut },
  { name: "DocumentScanner", Component: DocumentScanner },
  { name: "Savings", Component: Savings },
  { name: "AccountBalanceWallet", Component: AccountBalanceWallet },
  { name: "MonetizationOn", Component: MonetizationOn },
  { name: "Money", Component: Money },
  { name: "PriceCheck", Component: PriceCheck },
  { name: "Payment", Component: Payment },
  { name: "PointOfSale", Component: PointOfSale },
  { name: "Newspaper", Component: Newspaper },
  { name: "Storage", Component: Storage },
  { name: "LocalGroceryStore", Component: LocalGroceryStore },
  { name: "Store", Component: Store },
  { name: "MiscellaneousServices", Component: MiscellaneousServices },
  { name: "Wifi", Component: Wifi },
  { name: "SupportAgent", Component: SupportAgent },
  { name: "Sports", Component: Sports },
  { name: "DirectionsTransit", Component: DirectionsTransit },
  { name: "BusinessCenter", Component: BusinessCenter },
  { name: "ScienceOutlined", Component: ScienceOutlined },
  { name: "Settings", Component: Settings },
  { name: "SpeakerPhone", Component: SpeakerPhone },
  { name: "TranslateOutlined", Component: TranslateOutlined },
];

const IconContainer = styled(Typography)(({ theme, btncolor }) => ({
  color: btncolor,
}));

export const RenderIcon = ({ icon, color }) => {
  const [selectedIcon, setSelectedIcon] = useState({
    name: "N/A",
    Component: null,
  });

  useEffect(() => {
    if (icon !== "N/A") {
      for (let i = 0; i < Icons.length; i++) {
        if (Icons[i].name === icon) {
          setSelectedIcon({ name: icon, Component: Icons[i].Component });
          return;
        }
      }
    }
  }, [icon]);

  return (
    selectedIcon.Component && (
      <IconContainer btncolor={color}>
        <selectedIcon.Component />
      </IconContainer>
    )
  );
};
