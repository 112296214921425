import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DetailModal from "src/components/DetailModal/orderDetail";
import Iconify from "../components/Iconify";

// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { TableListHead, TableListToolbar } from "src/sections/@dashboard/table";
import bookInstance from "src/axios/bookInstance";
import { BookFilterMenu } from "src/helpers/MenuItems";
import { SUPER_ADMIN   } from "src/utils/Roles";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "no", label: "No.", alignRight: false, maxWidth: 50 },
  { id: "title", label: "Title", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "count", label: "Solutions", alignRight: false },
  { id: "orderCount", label: "Orders", alignRight: false },
  { id: "author", label: "Author", alignRight: false },
  { id: "chapter", label: "Chapters", alignRight: false },
  { id: "year", label: "Book Year" },
  { id: "isbn", label: "ISBN" },
];

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const StyledLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  ":hover": {
    color: "#36b693",
  },
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

let timeout = null;
export default function Book() {
  const [pageNumber, setPageNumber] = useState(1);
  const [bookCount, setBookCount] = useState(1);
  const [booksData, setBooksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const [bookDetailOpen, setBookDetailOpen] = useState("");
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState({
    key: "",
    filterBy: "",
  });
  const [searchLoading, setSearchLoading] = useState(false);
  let navigate = useNavigate();
  const { role: userRole } = useSelector((state) => state.auth);


  const onFilterChange = (filterKey, filterValue) => {
    setFilter({
      key: filterKey,
      filterBy: filterValue,
    });
  };
  useEffect(() => {
    const getBooks = async () => {
      try {
        setLoading(true);
        const bookResponse = await bookInstance.get("/", {
          params: {
            page: pageNumber,
            limit: limit,
            [filter.key]: filter.filterBy,
          },
        });
        setBookCount(bookResponse.data.totalBook);
        setBooksData(bookResponse.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (!query) {
      getBooks();
    }
  }, [pageNumber, limit, filter.key, filter.filterBy, query]);

  const onSearch = (value) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setQuery(value);
      if (value) {
        searchBook(value, 1, limit);

        setPageNumber(1);
      } else {
        searchBook(value, 1, limit);
      }
    }, 1000);
  };

  const searchBook = async (searchedQuery, page, queryLimit) => {
    try {
      setSearchLoading(true);
      const searchBookResponse = await bookInstance.get("/search", {
        params: {
          searchedQuery: searchedQuery,
          page: page,
          limit: queryLimit,
        },
      });
      setSearchLoading(false);
      setBooksData(searchBookResponse.data.data);
      setBookCount(searchBookResponse.data.totalBook);
    } catch (error) {
      setSearchLoading(false);
    }
  };

  const findSum = (solutions) => {
    if (solutions?.length === 0) return 0;
    return solutions?.reduce(
      (partialSum, solution) => partialSum + solution?.orderCount,
      0
    );
  };

  return (
    <>
      {loading && (
        <LoaderWrapper>
          <CircularProgress size={80} />
        </LoaderWrapper>
      )}

      <Page title="Book">
        <DetailModal
          isOpen={bookDetailOpen ? true : false}
          setOpen={() => setBookDetailOpen("")}
          from="Book"
          instance={bookInstance}
          route="book-detail"
          query={{
            bookId: bookDetailOpen,
          }}
        />
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Book
            </Typography>
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/add-book?isEdit=false"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Book
            </Button>
          </Stack>
          {!loading && (
            <>
              <Card>
                <Scrollbar>
                  <TableListToolbar
                    onFilterChange={onFilterChange}
                    currentFilter={filter}
                    filterList={BookFilterMenu}
                    showSearchBar={true}
                    placeholder="Search Books"
                    onSearch={onSearch}
                    isLoading={searchLoading}
                  />
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableListHead headLabel={TABLE_HEAD} />
                      <TableBody>
                        {booksData.map((row) => {
                          const {
                            _id,
                            title,
                            authorName,
                            bookNumber,
                            solutionCount,
                            categoryId,
                            year,
                            isbn,
                          } = row;
                          return (
                            <TableRow
                              hover
                              key={_id}
                              onClick={(e) => {
                                navigate(`/dashboard/book-detail/${_id}`);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                              >
                                {bookNumber}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="normal"
                                style={{
                                  maxWidth: 75,
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {title}
                                </Stack>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <StyledLink
                                    onClick={(e) => e.stopPropagation()}
                                    to={`/dashboard/category-detail/${categoryId?._id}`}
                                  >
                                    {categoryId?.name}
                                  </StyledLink>
                                </Stack>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                              >
                                {solutionCount?.length}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    userRole === SUPER_ADMIN &&
                                    findSum(solutionCount) !== 0
                                  ) {
                                    setBookDetailOpen(_id);
                                  }
                                }}
                                sx={{
                                  "&:hover": {
                                    color: "#36b693",
                                  },
                                }}
                              >
                                {findSum(solutionCount)}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                              >
                                {authorName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                              ></TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                              >
                                {year}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 75,
                                }}
                              >
                                {isbn}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
              <PaginationWrapper>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100, 500]}
                  component="div"
                  count={bookCount}
                  rowsPerPage={limit}
                  page={pageNumber - 1}
                  onPageChange={(event, value) => {
                    setPageNumber(value + 1);
                  }}
                  onRowsPerPageChange={(event) => {
                    setLimit(parseInt(event.target.value, 10));
                    setPageNumber(1);
                  }}
                />
              </PaginationWrapper>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}
