import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Button,
  CircularProgress,
  Switch,
  TablePagination,
  Grid,
  CardActionArea,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { TableListHead } from "src/sections/@dashboard/table";
import siteSettingInstance from "src/axios/siteSettingInstance";
import sitemapInstance from "src/axios/sitemapInstance";
import Iconify from "../components/Iconify";
import { toast } from "react-toastify";

const TABLE_HEAD = [
  { id: "settingname", label: "Setting Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export default function Member() {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [settingData, setSettingData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [settingCount, setSettingCount] = useState(1);
  const [sitemapsStats, setSitemapsStats] = useState({});

  useEffect(() => {
    getSettings();
    getSitemapsStats();
  }, [pageNumber, limit]);

  const getSettings = async () => {
    try {
      setLoading(true);
      const settingResponse = await siteSettingInstance.get("/", {
        params: {
          page: pageNumber,
          limit: limit,
        },
      });
      setLoading(false);
      setSettingData(settingResponse.data.data);
      setSettingCount(settingResponse.data.totalSettings);
    } catch (error) {
      setLoading(false);
    }
  };

  const getSitemapsStats = async () => {
    try {
      const sitemapResponse = await sitemapInstance.get(
        "/get-sitemap-files-stats"
      );
      setSitemapsStats(sitemapResponse.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateHistoryStatus = (index) => {
    const updatedHistoryData = [...settingData];
    updatedHistoryData[index].status = !updatedHistoryData[index].status;
    setSettingData(updatedHistoryData);
  };

  const handleStatusChange = async (e, _id, index) => {
    e.stopPropagation();
    try {
      updateHistoryStatus(index);

      await siteSettingInstance.post("/update-status", {
        _id: _id,
        status: e.target.checked,
      });
    } catch (error) {
      console.log(error.message);
      updateHistoryStatus(index);
    }
  };

  const createSitemaps = async () => {
    try {
      await sitemapInstance.post("/create-sitemaps");

      toast.success("Sitemap generation in progress ...", {
        position: "bottom-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Page title="Members">
      <Container>
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={80} />
          </LoaderWrapper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Site Settings
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/new-site-setting"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Create New Settings
          </Button>
        </Stack>
        {!loading && (
          <>
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {settingData?.map((row, index) => {
                        const { _id, settingName, status } = row;
                        return (
                          <TableRow hover key={_id}>
                            <TableCell align="left">{settingName}</TableCell>
                            <TableCell align="left">
                              <Switch
                                checked={status}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  handleStatusChange(e, _id, index);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
            <PaginationWrapper>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 500]}
                component="div"
                count={settingCount}
                rowsPerPage={limit}
                page={pageNumber - 1}
                onPageChange={(event, value) => {
                  setPageNumber(value + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPageNumber(1);
                }}
              />
            </PaginationWrapper>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="h4" gutterBottom>
                  Sitemap Settings
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button variant="contained" onClick={createSitemaps}>
                  Create Sitemaps
                </Button>
              </Grid>
              {sitemapsStats.length > 0 && (
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <TableListHead
                          headLabel={[
                            {
                              id: "sitemapFileName",
                              label: "Sitemap File Name",
                            },

                            { id: "capacity", label: "Maximum Capacity" },
                            {
                              id: "currentVolume",
                              label: "Current Capacity",
                            },
                          ]}
                        />
                        <TableBody>
                          {sitemapsStats?.map((row, index) => {
                            const { name, capacity, currentVolume } = row;
                            return (
                              <TableRow hover key={name}>
                                <TableCell align="left">{name}</TableCell>
                                <TableCell align="left">{capacity}</TableCell>
                                <TableCell align="left">
                                  {currentVolume}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
}
