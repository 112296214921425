// material-ui
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Box,
  Grid,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";

//axios
import bookInstance from "src/axios/bookInstance";
import { getToken } from "src/store/localStorage";

const BookForm = () => {
  const navigate = useNavigate();
  const { bookId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = JSON.parse(searchParams.get("isEdit"));
  const token = getToken();
  const [bookDetail, setBookDetail] = useState({
    title: "",
    description: "",
    isbn: "",
    author: "",
    publisher: "",
    year: "",
    edition: "",
  });
  const [loadingBook, setLoadingBook] = useState(false);

  useEffect(() => {
    const getBookDetail = async () => {
      try {
        setLoadingBook(true);
        const bookDetailResponse = await bookInstance.get(
          `/book-detail?bookId=${bookId}`,
          {
            headers: {
              "x-access-token": `${token}`,
            },
          }
        );

        const oldDetail = {
          title: bookDetailResponse.data.detail?.title || "",
          description: bookDetailResponse.data.detail?.description || "",
          author: bookDetailResponse.data.detail?.authorName || "",
          publisher: bookDetailResponse.data.detail?.publisher || "",
          year: bookDetailResponse.data.detail?.year || "",
          edition: bookDetailResponse.data.detail?.edition || "",
          isbn: bookDetailResponse.data.detail?.isbn || "",
        };

        setBookDetail(oldDetail);
        setLoadingBook(false);
      } catch (err) {
        setLoadingBook(false);
      }
    };

    if (bookId && isEdit === true) {
      getBookDetail();
    }
  }, [bookId, token, isEdit]);

  const handleAddBook = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    try {
      const url =
        isEdit === true ? `/update-book?bookId=${bookId}` : "/add-book";

      const requestMethod =
        isEdit === true ? bookInstance.put : bookInstance.post;

      const bookResponse = await requestMethod(
        url,
        {
          title: values.title,
          description: values.description,
          isbn: values.isbn,
          author: values.author,
          publisher: values.publisher,
          year: values.year,
          edition: values.edition,
        },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      );
      if (bookResponse.status === 200) {
        resetForm();
        navigate("/dashboard/book");
      }
    } catch (err) {}
  };

  return (
    <>
      <Formik
        initialValues={{
          title: bookDetail.title,
          description: bookDetail.description,
          isbn: bookDetail.isbn,
          author: bookDetail.author,
          publisher: bookDetail.publisher,
          year: bookDetail.year,
          edition: bookDetail.edition,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(255).required("Title is required"),
          description: Yup.string().required("description is required"),
          isbn: Yup.string().required("isbn is required"),
          author: Yup.string().required("author is required"),
          publisher: Yup.string().required("publisher is required"),
          year: Yup.string().required("year is required"),
          edition: Yup.string().required("edition is required"),
        })}
        enableReinitialize={true}
        onSubmit={handleAddBook}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.title && errors.title)}
                >
                  <InputLabel htmlFor="outlined-title">Title</InputLabel>
                  <OutlinedInput
                    id="outlined-title"
                    type="text"
                    value={values.title}
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Book Title"
                    inputProps={{}}
                  />
                  {touched.title && errors.title && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-title"
                    >
                      {errors.title}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.author && errors.author)}
                >
                  <InputLabel htmlFor="outlined-author">Author</InputLabel>
                  <OutlinedInput
                    id="outlined-author"
                    type="text"
                    value={values.author}
                    name="author"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Book author"
                    inputProps={{}}
                  />
                  {touched.author && errors.author && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-autho"
                    >
                      {errors.author}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.isbn && errors.isbn)}
                >
                  <InputLabel htmlFor="outline-isbn">ISBN</InputLabel>
                  <OutlinedInput
                    id="outline-isbn"
                    type="text"
                    value={values.isbn}
                    name="isbn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Book ISBN"
                    inputProps={{}}
                  />
                  {touched.isbn && errors.isbn && (
                    <FormHelperText error id="standard-weight-helper-isbn">
                      {errors.isbn}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.publisher && errors.publisher)}
                >
                  <InputLabel htmlFor="outlined-publisher">
                    Publisher
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-publisher"
                    type="text"
                    value={values.publisher}
                    name="publisher"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Book Publisher"
                    inputProps={{}}
                  />
                  {touched.publisher && errors.publisher && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-publisher"
                    >
                      {errors.publisher}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.year && errors.year)}
                >
                  <InputLabel htmlFor="outlined-year">Year</InputLabel>
                  <OutlinedInput
                    id="outlined-year"
                    type="text"
                    value={values.year}
                    name="year"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Book Year"
                    inputProps={{}}
                  />
                  {touched.year && errors.year && (
                    <FormHelperText error id="standard-weight-helper-text-year">
                      {errors.year}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.edition && errors.edition)}
                >
                  <InputLabel htmlFor="outlined-edition">Edition</InputLabel>
                  <OutlinedInput
                    id="outlined-edition"
                    type="text"
                    value={values.edition}
                    name="edition"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Book Edition"
                    inputProps={{}}
                  />
                  {touched.edition && errors.edition && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-edition"
                    >
                      {errors.edition}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.description && errors.description)}
                >
                  <InputLabel htmlFor="outlined-description">
                    Description
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-description"
                    type="text"
                    value={values.description}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Book Description"
                    inputProps={{}}
                  />
                  {touched.description && errors.description && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-description"
                    >
                      {errors.description}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 2, position: "relative" }}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || loadingBook}
                  >
                    {isEdit ? "Update" : "Add"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default BookForm;
