// material-ui
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Stack,
  Divider,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import solutionInstance from "src/axios/solutionInstance";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { ADMIN, SUPER_ADMIN } from "src/utils/Roles";

// third party
import ReactHtmlParser from "html-react-parser";
export const InfoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: 0,
  padding: "10px",
  backgroundColor: "#f7f7f7",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  ":hover": {
    color: "#36b693",
  },
}));
const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));
const SolutionDetail = () => {
  const [solutionData, setSolutionData] = useState();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answer, setAnswer] = useState("");
  const { role: currentRole } = useSelector((state) => state.auth);

  const { id } = useParams();

  useEffect(() => {
    const getSolution = async () => {
      try {
        setLoading(true);
        const solutionResponse = await solutionInstance.get(
          "/solution-detail",
          {
            params: {
              solutionId: id,
            },
          }
        );
        setLoading(false);

        setSolutionData(solutionResponse.data.detail);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getSolution();
  }, [id]);

  const getAnswerOnly = async () => {
    try {
      if (answer) {
        setAnswer("");
        return;
      }
      setIsSubmitting(true);
      const solutionResponse = await solutionInstance.get("/solution-answer", {
        params: {
          solutionId: id,
        },
      });

      setAnswer(solutionResponse.data.answer);
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <CircularProgress size={80} />
        </LoaderWrapper>
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Solution Detail
            </Typography>
          </Stack>

          <Grid container spacing={4}>
            <Grid container item spacing={4} xs={12}>
              <Grid item xs={4}>
                <InfoPaper>
                  <Typography variant="h5">Book Name:</Typography>
                  <Divider sx={{ margin: "5px 0" }} />
                  <Typography variant="caption">
                    {currentRole === SUPER_ADMIN || currentRole === ADMIN ? (
                      <StyledLink
                        to={`/dashboard/book-detail/${solutionData?.bookId}`}
                      >
                        {solutionData?.bookName}
                      </StyledLink>
                    ) : (
                      <>{solutionData?.bookName}</>
                    )}
                  </Typography>
                </InfoPaper>
              </Grid>
              <Grid item xs={4}>
                <InfoPaper>
                  <Typography variant="h5">Category Name:</Typography>
                  <Divider sx={{ margin: "5px 0" }} />
                  <Typography variant="caption">
                    {currentRole === SUPER_ADMIN || currentRole === ADMIN ? (
                      <StyledLink
                        to={`/dashboard/category-detail/${solutionData?.categoryId}`}
                      >
                        {solutionData?.categoryName}
                      </StyledLink>
                    ) : (
                      <>{solutionData?.categoryName}</>
                    )}
                  </Typography>
                </InfoPaper>
              </Grid>
              <Grid item xs={4}>
                <InfoPaper>
                  <Typography variant="h5">No of Downloads:</Typography>
                  <Divider sx={{ margin: "5px 0" }} />
                  <Typography variant="caption">
                    {solutionData?.noOfDownloads}
                  </Typography>
                </InfoPaper>
              </Grid>
              <Grid item xs={4}>
                <InfoPaper>
                  <Typography variant="h5">Views:</Typography>
                  <Divider sx={{ margin: "5px 0" }} />
                  <Typography variant="caption">
                    {solutionData?.views}
                  </Typography>
                </InfoPaper>
              </Grid>
              <Grid item xs={4}>
                <InfoPaper>
                  <Typography variant="h5">Price:</Typography>
                  <Divider sx={{ margin: "5px 0" }} />
                  <Typography variant="caption">
                    {solutionData?.price}
                  </Typography>
                </InfoPaper>
              </Grid>
              <Grid item xs={4}>
                <InfoPaper>
                  <Typography variant="h5">No of Orders:</Typography>
                  <Divider sx={{ margin: "5px 0" }} />
                  <Typography variant="caption">
                    {solutionData?.noOfOrders}
                  </Typography>
                </InfoPaper>
              </Grid>
              <Grid item xs={12}>
                <InfoPaper>
                  <Typography variant="h5">Question:</Typography>
                  <Divider sx={{ margin: "5px 0" }} />
                  <Typography variant="caption">
                    {solutionData && ReactHtmlParser(solutionData?.question)}
                  </Typography>
                </InfoPaper>
              </Grid>
              {currentRole === SUPER_ADMIN ? (
                <Grid item xs={12}>
                  <InfoPaper>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h5">Answer:</Typography>
                      <LoadingButton
                        fullWidth
                        size="medium"
                        onClick={getAnswerOnly}
                        variant="contained"
                        loading={isSubmitting}
                        sx={{ width: "150px" }}
                      >
                        {answer ? "Hide Answer" : "View Answer"}
                      </LoadingButton>
                    </Stack>

                    <Divider sx={{ margin: "5px 0" }} />
                    {answer && (
                      <Typography variant="caption">
                        {answer && ReactHtmlParser(answer)}
                      </Typography>
                    )}
                  </InfoPaper>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SolutionDetail;
