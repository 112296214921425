import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Divider,
  Container,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import ReactHtmlParser from "html-react-parser";
import orderInstance from "src/axios/orderInstance";
import locationInstance from "src/axios/locationInstace";
import { useSelector } from "react-redux";

import Page from "../components/Page";
import { ADMIN, SUPER_ADMIN, MANAGER } from "src/utils/Roles";

const InfoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "5px",
  padding: "1rem",
  height: "100%",
}));
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));
const Detail = styled(Typography)(({ theme }) => ({
  fontSize: ".9rem",
  marginTop: "1rem",
}));
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  ":hover": {
    color: "#36b693",
  },
}));
const CustomDivider = styled(Divider)(({ theme }) => ({
  margin: "10px 0",
  opacity: "25%",
}));
const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const OrderDetail = () => {
  const [orderData, setOrderData] = useState();
  const { orderToken } = useParams();
  const [loading, setLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const { role: userRole } = useSelector((state) => state.auth);

  useEffect(() => {
    const getOrderDetail = async () => {
      try {
        setLoading(true);
        const orderResponse = await orderInstance.get("/order-details", {
          params: {
            orderToken: orderToken,
          },
        });
        setOrderData(orderResponse.data.detail);
        setIpAddress(orderResponse?.data?.detail?.ipAddress);

        let location = {};
        if (orderResponse.data?.detail?.ipAddress) {
          const locationResponse = await locationInstance.get("/" + ipAddress);
          location = {
            country: locationResponse.data.country_name || "",
            state: locationResponse.data.state || "N/A",
            city: locationResponse.data.city || "N/A",
          };
        }
        setOrderData({
          ...location,
          ...orderResponse.data.detail,
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    if (orderToken) getOrderDetail();
    // eslint-disable-next-line
  }, [orderToken]);

  return (
    <Page title="Order-Detail">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Order Detail
          </Typography>
        </Stack>
        {loading ? (
          <LoaderWrapper>
            <CircularProgress size={80} />
          </LoaderWrapper>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InfoPaper>
                <Label>Status</Label>

                <Detail>{orderData?.status}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoPaper>
                <Label>View Count</Label>

                <Detail>{orderData?.viewCount}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoPaper>
                <Label>Download Count</Label>

                <Detail>{orderData?.downloadCount}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoPaper>
                <Label>Customer Name</Label>

                <Detail>{orderData?.customerName}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InfoPaper>
                <Label>Country</Label>

                <Detail>{orderData?.country}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InfoPaper>
                <Label>State</Label>

                <Detail>{orderData?.state}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InfoPaper>
                <Label>City</Label>

                <Detail>{orderData?.city}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InfoPaper>
                <Label>Category</Label>

                <Detail>
                  {userRole === SUPER_ADMIN ? (
                    <StyledLink
                      to={`/dashboard/category-detail/${orderData?.categoryId}`}
                    >
                      {orderData?.solutionCategory}
                    </StyledLink>
                  ) : (
                    <Typography>{orderData?.solutionCategory}</Typography>
                  )}
                </Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InfoPaper>
                <Label>Amount</Label>

                <Detail>{orderData?.amount}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InfoPaper>
                <Label>Payment Type</Label>

                <Detail>{orderData?.paymentType}</Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12}>
              <InfoPaper>
                <Label>Book</Label>

                <Detail>
                  {userRole === SUPER_ADMIN ? (
                    <StyledLink
                      to={`/dashboard/book-detail/${orderData?.bookId}`}
                    >
                      {orderData?.bookTitle}
                    </StyledLink>
                  ) : (
                    <Typography>{orderData?.bookTitle}</Typography>
                  )}
                </Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12}>
              <InfoPaper>
                <Label>Question Title</Label>

                <Detail>
                  {userRole === SUPER_ADMIN || userRole === ADMIN ? (
                    <StyledLink
                      to={`/dashboard/solution-detail/${orderData?.solutionId}`}
                    >
                      {orderData?.solutionTitle}
                    </StyledLink>
                  ) : (
                    <Typography>{orderData.solutionTitle}</Typography>
                  )}
                </Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
            <Grid item xs={12}>
              <InfoPaper>
                <Label>Question Description</Label>

                <Detail>
                  {orderData !== undefined &&
                    ReactHtmlParser(orderData?.question)}
                </Detail>
              </InfoPaper>
              <CustomDivider />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default OrderDetail;
