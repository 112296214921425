// material-ui

import React from "react";

import { useNavigate } from "react-router-dom";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Box,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";

//axios
import siteSettingInstance from "src/axios/siteSettingInstance";

//project

const AddSettingForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const status = [
    { value: "true", label: "Enable" },
    {
      value: "false",
      label: "Disable",
    },
  ];

  const handleAddNewSetting = async (
    values,

    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const res = await siteSettingInstance.post("/new-site-setting", {
        settingName: values.settingName,
        status: values.status,
      });
      if (res.status === 200) {
        navigate("/dashboard/site-setting");
      }
    } catch (err) {
      setErrors({ submit: err.response.data.message });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          settingName: "",
          status: "Enable",
        }}
        validationSchema={Yup.object().shape({
          settingName: Yup.string()
            .min(3)
            .max(255)
            .required("Setting Name is required"),
          status: Yup.string().max(12).required("status is required"),
        })}
        onSubmit={handleAddNewSetting}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid container item spacing={4} xs={12}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.settingName && errors.settingName)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-name">
                      Setting Name
                    </InputLabel>

                    <OutlinedInput
                      id="outlined-name"
                      type="text"
                      value={values.settingName}
                      name="settingName"
                      onBlur={handleBlur}
                      onChange={(event) => handleChange(event)}
                      label="settingName"
                      inputProps={{}}
                    />

                    {touched.settingName && errors.settingName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name"
                      >
                        {errors.settingName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.status && errors.status)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-email-login">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="status"
                      value={values.status}
                      label="status"
                      onChange={handleChange}
                    >
                      {status.map((val) => {
                        return (
                          <MenuItem value={Boolean(val.value)}>
                            {val.label}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    {touched.status && errors.status && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.status}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 2, position: "relative" }}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Add New Setting
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddSettingForm;
