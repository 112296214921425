import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import { TableListHead, TableListToolbar } from "src/sections/@dashboard/table";

import categoryInstance from "src/axios/categoryInstance";
import { CategoryFilterMenu } from "src/helpers/MenuItems";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "parent", label: "Parent Category", alignRight: false },
  { id: "level", label: "Level", alignRight: false },
  { id: "edit", label: "Edit", alignRight: false },
];

const ButtonWrapper = styled(Button)(({ theme, bordercolor, hovercolor }) => ({
  padding: ".6rem 0",
  border: "1px solid",
  borderColor: bordercolor,
  "&:hover": {
    backgroundColor: hovercolor,
  },
}));

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export default function Category() {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [categoryCount, setCategoryCount] = useState(1);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState({
    key: "",
    filterBy: "",
  });

  const onFilterChange = (filterKey, filterValue) => {
    setFilter({
      key: filterKey,
      filterBy: filterValue,
    });
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        setLoading(true);
        const categoryResponse = await categoryInstance.get("/", {
          params: {
            page: pageNumber,
            limit: limit,
            sort: "level",
            [filter.key]: filter.filterBy,
          },
        });
        setCategoryCount(categoryResponse.data.totalCategories);
        setCategoryData(categoryResponse.data.categories);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    getCategories();
  }, [pageNumber, limit, filter.key, filter.filterBy]);
  return (
    <Container>
      {loading && (
        <LoaderWrapper>
          <CircularProgress size={80} />
        </LoaderWrapper>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Categories
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/dashboard/add-category?isEdit=false"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          New Category
        </Button>
      </Stack>
      {!loading && (
        <>
          <Card>
            <Scrollbar>
              <TableListToolbar
                currentFilter={filter}
                onFilterChange={onFilterChange}
                filterList={CategoryFilterMenu}
              />
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {categoryData.map((row) => {
                      const { _id, name, parentId, level } = row;
                      return (
                        <TableRow
                          hover
                          key={_id}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`/dashboard/category-detail/${_id}`);
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              maxWidth: 100,
                            }}
                          >
                            {name}
                          </TableCell>
                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              sx={{
                                pointerEvents:
                                  parentId?.name === "NA" ? "none" : "all",
                                "&:hover": {
                                  color: "#36b693",
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (parentId?.name) {
                                  navigate(
                                    `/dashboard/category-detail/${parentId?._id}`
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {parentId?.name || "N/A"}
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{level || "N/A"}</TableCell>
                          <TableCell>
                            <ButtonWrapper
                              bordercolor="green"
                              hovercolor="#c2f5b8"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `/dashboard/update-category/${_id}?isEdit=true`
                                );
                              }}
                            >
                              <Iconify
                                icon="eva:edit-fill"
                                size={40}
                                color="green"
                              />
                            </ButtonWrapper>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
          <PaginationWrapper>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 500]}
              component="div"
              count={categoryCount}
              rowsPerPage={limit}
              page={pageNumber - 1}
              onPageChange={(event, value) => {
                setPageNumber(value + 1);
              }}
              onRowsPerPageChange={(event) => {
                setLimit(parseInt(event.target.value, 10));
                setPageNumber(1);
              }}
            />
          </PaginationWrapper>
        </>
      )}
    </Container>
  );
}
