// material-ui
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Grid, Typography, Paper, Stack, Divider, Button } from "@mui/material";
import { styled } from "@mui/system";

import bookInstance from "src/axios/bookInstance";
import Iconify from "../components/Iconify";

// third party
import ReactHtmlParser from "html-react-parser";

export const InfoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: 0,
  padding: "10px",
  backgroundColor: "#f7f7f7",
}));

const BookDetail = () => {
  const [bookData, setBookData] = useState();

  const { id } = useParams();
  useEffect(() => {
    const getBooks = async () => {
      try {
        const bookResponse = await bookInstance.get("/book-detail", {
          params: {
            bookId: id,
          },
        });
        setBookData(bookResponse.data.detail);
      } catch (error) {
        console.log(error);
      }
    };
    getBooks();
  }, [id]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Book Detail
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/dashboard/update-book/${id}?isEdit=true`}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Edit Book
        </Button>
      </Stack>

      <Grid container spacing={4}>
        <Grid container item spacing={4} xs={12}>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Title:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">{bookData?.title}</Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Author Name:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">{bookData?.authorName}</Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Category:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">{bookData?.category}</Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={8}>
            <InfoPaper>
              <Typography variant="h5">Description:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">
                {bookData && ReactHtmlParser(bookData?.description)}
              </Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Year:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">{bookData?.year}</Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Publisher:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">{bookData?.publisher}</Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">ISBN:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">{bookData?.isbn}</Typography>
            </InfoPaper>
          </Grid>
          <Grid item xs={4}>
            <InfoPaper>
              <Typography variant="h5">Edition:</Typography>
              <Divider sx={{ margin: "5px 0" }} />
              <Typography variant="caption">
                {bookData && bookData?.edition}
              </Typography>
            </InfoPaper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BookDetail;
