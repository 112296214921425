import React, { useState, useEffect } from "react";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { TableListHead } from "src/sections/@dashboard/table";
import solutionLogInstance from "src/axios/searchLogInstance";

const TABLE_HEAD = [
  { id: "query", label: "Query", alignRight: false },
  { id: "count", label: "Count", alignRight: false },
  { id: "ip", label: "IP", alignRight: false },
];

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export default function SearchLog() {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [logCount, setLogCount] = useState(1);

  useEffect(() => {
    const getSearchLog = async () => {
      try {
        setLoading(true);
        const searchLogResponse = await solutionLogInstance.get("/", {
          params: {
            page: pageNumber,
            limit: limit,
          },
        });
        setLoading(false);
        setData(searchLogResponse.data.data);
        setLogCount(searchLogResponse.data.totalSearchlog);
      } catch (error) {
        setLoading(false);
      }
    };
    getSearchLog();
  }, [pageNumber, limit]);

  return (
    <Page title="solution-log">
      <Container>
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={80} />
          </LoaderWrapper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Search Logs
          </Typography>
        </Stack>
        {!loading && (
          <>
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {data?.map((row) => {
                        const { _id, count, query } = row;
                        return (
                          <TableRow hover key={_id}>
                            <TableCell align="left">{query}</TableCell>
                            <TableCell align="left">{count}</TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
            <PaginationWrapper>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 500]}
                component="div"
                count={logCount}
                rowsPerPage={limit}
                page={pageNumber - 1}
                onPageChange={(event, value) => {
                  setPageNumber(value + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPageNumber(1);
                }}
              />
            </PaginationWrapper>
          </>
        )}
      </Container>
    </Page>
  );
}
