import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Button,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { TableListHead, TableListToolbar } from "src/sections/@dashboard/table";
import memberInstance from "src/axios/memberInstance";
import DetailModal from "src/components/DetailModal";
import Iconify from "../components/Iconify";
import { MembersFilterMenu } from "src/helpers/MenuItems";

const TABLE_HEAD = [
  { id: "question", label: "First Name", alignRight: false, maxWidth: 50 },
  { id: "author", label: "Last Name", alignRight: false, maxWidth: 50 },
  { id: "bookname", label: "Email", alignRight: false, maxWidth: 50 },
  { id: "category", label: "Role", alignRight: false, maxWidth: 50 },
  { id: "price", label: "Gender", alignRight: false, maxWidth: 50 },
];

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export default function Member() {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [userCount, setUserCount] = useState(1);
  const [memberDetailOpen, setMemberDetailOpen] = useState("");

  const [filter, setFilter] = useState({
    key: "",
    filterBy: "",
  });

  const onFilterChange = (filterKey, filterValue) => {
    setFilter({
      key: filterKey,
      filterBy: filterValue,
    });
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading(true);
        const userResponse = await memberInstance.get("/", {
          params: {
            page: pageNumber,
            limit: limit,
            [filter.key]: filter.filterBy,
          },
        });
        setLoading(false);
        setUserData(userResponse.data.users);
        setUserCount(userResponse.data.totalUsers);
      } catch (error) {
        setLoading(false);
      }
    };

    getOrders();
  }, [pageNumber, limit, filter.filterBy, filter.key]);

  return (
    <Page title="Members">
      <DetailModal
        isOpen={memberDetailOpen ? true : false}
        setOpen={() => setMemberDetailOpen("")}
        from="Members"
        instance={memberInstance}
        route={"/admin-detail"}
        query={{
          userId: memberDetailOpen,
        }}
      />
      <Container>
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={80} />
          </LoaderWrapper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Members
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/add-member"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add new Member
          </Button>
        </Stack>
        {!loading && (
          <>
            <Card>
              <TableListToolbar
                currentFilter={filter}
                onFilterChange={onFilterChange}
                filterList={MembersFilterMenu}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {userData.map((row, i) => {
                        const {
                          _id,
                          email,
                          firstName,
                          lastName,
                          role,
                          gender,
                        } = row;
                        return (
                          <TableRow
                            onClick={() => setMemberDetailOpen(_id)}
                            hover
                            key={_id}
                          >
                            <TableCell
                              component="th"
                              size="small"
                              padding="normal"
                              style={{
                                maxWidth: 150,
                              }}
                              onClick={() => setMemberDetailOpen(_id)}
                            >
                              <Typography variant="body2">
                                {firstName}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" style={{ maxWidth: 150 }}>
                              {lastName}
                            </TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{role}</TableCell>
                            <TableCell align="left">{gender}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
            <PaginationWrapper>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 500]}
                component="div"
                count={userCount}
                rowsPerPage={limit}
                page={pageNumber - 1}
                onPageChange={(event, value) => {
                  setPageNumber(value + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPageNumber(1);
                }}
              />
            </PaginationWrapper>
          </>
        )}
      </Container>
    </Page>
  );
}
