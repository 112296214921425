// material-ui

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Box,
  Grid,
  IconButton,
  Select,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";

import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

// third party

import * as Yup from "yup";

import { Formik } from "formik";

import { LoadingButton } from "@mui/lab";

//axios

import memberInstance from "src/axios/memberInstance";

import { getToken } from "src/store/localStorage";
//
import { useSelector } from "react-redux";
import { ADMIN, FINANCE, MANAGER, SEARCHER, SUPER_ADMIN } from "src/utils/Roles";

//project

import { EmailRegEx } from "src/utils/emailValidationRegex";

const AddUserForm = () => {
  const theme = useTheme();
  const { role: userRole } = useSelector((state) => state.auth);
  const token = getToken();

  const navigate = useNavigate();

  let roledUsers = [SUPER_ADMIN, ADMIN, MANAGER, SEARCHER, FINANCE];

  const userGender = ["male", "female", "other"];

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (userRole !== SUPER_ADMIN) {
    roledUsers = [...roledUsers.filter((value) => value !== SUPER_ADMIN)];
  }

  const handleAddUser = async (
    values,

    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (token) {
        if (userRole !== SUPER_ADMIN && values.role === SUPER_ADMIN) {
          setErrors({ submit: "Role not found!!!" });
          return;
        }
        await memberInstance.post(
          "/add-member",

          {
            firstName: values.firstName,

            lastName: values.lastName,

            email: values.email,

            password: values.password,

            passwordConfirm: values.passwordConfirm,

            role: values.role,

            gender: values.gender,
          },

          {
            headers: { "x-access-token": `${token}` },
          }
        );

        navigate("/dashboard/members");
      }
    } catch (err) {
      setErrors({ submit: err.response.data.message });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",

          lastName: "",

          email: "",

          password: "",

          passwordConfirm: "",

          role: "admin",

          gender: "male",
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()

            .min(3)

            .max(255)

            .required("firstName is required"),

          lastName: Yup.string().max(255).required("lastName is required"),

          email: Yup.string()

            .max(255)

            .required("Email is required")

            .matches(EmailRegEx, "Email must be valid"),

          password: Yup.string()

            .min(9)

            .max(12)

            .required("Password is required"),

          passwordConfirm: Yup.string()

            .min(9)

            .max(12)

            .required("Confirm Password is required"),

          role: Yup.string().max(12).required("Role is required"),

          gender: Yup.string().max(12).required("gender is required"),
        })}
        onSubmit={handleAddUser}
      >
        {({
          errors,

          handleBlur,

          handleChange,

          handleSubmit,

          isSubmitting,

          touched,

          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid container item spacing={4} xs={12}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.firstName && errors.firstName)}
                  >
                    <InputLabel htmlFor="outlined-name">First Name</InputLabel>

                    <OutlinedInput
                      id="outlined-name"
                      type="text"
                      value={values.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={(event) => handleChange(event)}
                      label="firstName"
                      inputProps={{}}
                    />

                    {touched.firstName && errors.firstName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name"
                      >
                        {errors.firstName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.lastName && errors.lastName)}
                  >
                    <InputLabel htmlFor="outlined-name">Last Name</InputLabel>

                    <OutlinedInput
                      id="outlined-name"
                      type="text"
                      value={values.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={(event) => handleChange(event)}
                      label="lastName"
                      inputProps={{}}
                    />

                    {touched.lastName && errors.lastName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name"
                      >
                        {errors.lastName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                  >
                    <InputLabel htmlFor="outlined-name">Email</InputLabel>

                    <OutlinedInput
                      id="outlined-name"
                      type="text"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={(event) => handleChange(event)}
                      label="email"
                      inputProps={{}}
                    />

                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.gender && errors.gender)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-email-login">
                      Gender
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="gender"
                      value={values.gender}
                      label="gender"
                      onChange={handleChange}
                    >
                      {userGender.map((user) => {
                        return <MenuItem value={user}>{user}</MenuItem>;
                      })}
                    </Select>

                    {touched.gender && errors.gender && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.gender}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password-login">
                      Password
                    </InputLabel>

                    <OutlinedInput
                      id="outlined-adornment-password-login"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      autoComplete="new-password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      inputProps={{}}
                    />

                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.passwordConfirm && errors.passwordConfirm
                    )}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password-login">
                      Confirm Password
                    </InputLabel>

                    <OutlinedInput
                      id="outlined-adornment-password-login"
                      type={showPassword ? "text" : "password"}
                      value={values.passwordConfirm}
                      name="passwordConfirm"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="PasswordConfirm"
                      inputProps={{}}
                    />

                    {touched.passwordConfirm && errors.passwordConfirm && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.passwordConfirm}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={12}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.role && errors.role)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-email-login">
                      Role
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="role"
                      value={values.role}
                      label="Role"
                      onChange={handleChange}
                    >
                      {roledUsers.map((user) => (
                        <MenuItem value={user}>{user}</MenuItem>
                      ))}
                    </Select>

                    {touched.role && errors.role && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.role}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {errors?.submit && (
                    <FormHelperText error id="standard-weight-helper-text-name">
                      {errors?.submit}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 2, position: "relative" }}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Add New User
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddUserForm;
