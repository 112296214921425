import { useState, useEffect, useCallback } from "react";
// material
import {
  Card,
  Table,
  Stack,
  TableBody,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import {
  TableListHead,
  TableListToolbar,
  TableListRow,
} from "src/sections/@dashboard/table";

import waitingListInstance from "src/axios/waitingListInstance";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "email", label: "Title", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "state", label: "State", alignRight: false },
  { id: "city", label: "City", alignRight: false },
];

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  widht: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export default function Book() {
  const [pageNumber, setPageNumber] = useState(1);
  const [watingListCount, setWatingListCount] = useState(1);
  const [waitingList, setWaitingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(20);

  const getWaitingList = useCallback(async () => {
    try {
      setLoading(true);
      const emailResponse = await waitingListInstance.get("/", {
        params: {
          page: pageNumber,
          limit: limit,
        },
      });
      setWatingListCount(emailResponse.data.totalEmails);
      setWaitingList(emailResponse.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [pageNumber, limit]);

  useEffect(() => {
    getWaitingList();
  }, [getWaitingList]);

  return (
    <Page title="Categories">
      <Container>
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={80} />
          </LoaderWrapper>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Waiting List
          </Typography>
        </Stack>
        {!loading && (
          <>
            <Card>
              <Scrollbar>
                <TableListToolbar />
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {waitingList.map((row) => {
                        const { _id, email, ipAddress, status } = row;
                        return (
                          <TableListRow
                            key={_id}
                            email={email}
                            ipAddress={ipAddress}
                            status={status}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
            <PaginationWrapper>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 500]}
                component="div"
                count={watingListCount}
                rowsPerPage={limit}
                page={pageNumber - 1}
                onPageChange={(event, value) => {
                  setPageNumber(value + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPageNumber(1);
                }}
              />
            </PaginationWrapper>
          </>
        )}
      </Container>
    </Page>
  );
}
