import React, { useEffect } from "react";
import { Navigate, useRoutes, useNavigate } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
//
import Member from "../pages/Member";
import AddMember from "../pages/AddUserForm";
import Login from "../pages/Login";
import Order from "src/pages/Order";
import OrderDetail from "src/pages/OrderDetail";
import NotFound from "../pages/Page404";
import DashboardApp from "../pages/DashboardApp";
import Book from "../pages/Book";
import BookDetail from "src/pages/BookDetail";
import Solution from "../pages/Solution";
import Category from "src/pages/Category";
import CategoryDetail from "src/pages/CategoryDetail";
import WaitingList from "src/pages/WaitingList";
import AddCategoryForm from "src/pages/AddCategoryForm";
import SolutionDetail from "../pages/solutionDetail";
import ForgotPassword from "src/pages/ForgotPassword";
import ResetPassword from "src/pages/ResetPassword";
import SearchLog from "../pages/searchLog";
import BookForm from "src/pages/BookFrom";
import SiteSettings from "../pages/SiteSettings";
import AddSettingForm from "../pages/AddSettingForm";

//protected
import LoggedInProtection from "./LoggedInProtection";
import LoggedOutProtection from "./LoggedOutProtection";
import RoledProtection from "./RoledProtection";
import { getToken, deleteToken } from "src/store/localStorage";
import { loadProfile } from "src/store/actions/authActions";
import { useSelector, useDispatch } from "react-redux";

//
import {
  SUPER_ADMIN,
  ADMIN,
  MANAGER,
  SEARCHER,
  FINANCE,
} from "src/utils/Roles";

// ----------------------------------------------------------------------

export default function Router() {
  const { isSignedIn = false } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      const token = getToken();
      if (token && !isSignedIn) {
        const loadProfileResponse = await dispatch(loadProfile(token));
        if (loadProfileResponse) {
          deleteToken();
          navigate("/");
        }
      }
    };
    fetchProfile();
  }, [isSignedIn, dispatch, navigate]);

  return useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/login" /> },
        {
          path: "login",
          element: (
            <LoggedOutProtection redirectTo={"/dashboard/main"}>
              <Login />
            </LoggedOutProtection>
          ),
        },
        {
          path: "forgot-password",
          element: (
            <LoggedOutProtection redirectTo={"/dashboard/main"}>
              <ForgotPassword />
            </LoggedOutProtection>
          ),
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <LoggedInProtection redirectTo={"/"}>
          <DashboardLayout />
        </LoggedInProtection>
      ),
      children: [
        {
          path: "main",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <DashboardApp />
            </LoggedInProtection>
          ),
        },
        {
          path: "members",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN]}
                redirectTo="/dashboard/main"
              >
                <Member />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "add-member",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN]}
                redirectTo="/dashboard/main"
              >
                <AddMember />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "order",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, FINANCE]}
                redirectTo="/dashboard/main"
              >
                <Order />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: `order-detail/:orderToken`,
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, FINANCE]}
                redirectTo="/dashboard/main"
              >
                <OrderDetail />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "solution-detail/:id",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, SEARCHER]}
                redirectTo="/dashboard/main"
              >
                <SolutionDetail />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "category",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <Category />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "category-detail/:id",

          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <CategoryDetail />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "solution",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, SEARCHER]}
                redirectTo="/dashboard/main"
              >
                <Solution />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },

        {
          path: "book",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <Book />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "add-book",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <BookForm />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "update-book/:bookId",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <BookForm />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: `book-detail/:id`,
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <BookDetail />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "waiting-list",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN]}
                redirectTo="/dashboard/main"
              >
                <WaitingList />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "searchlog",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN]}
                redirectTo="/dashboard/main"
              >
                <SearchLog />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "site-setting",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN]}
                redirectTo="/dashboard/main"
              >
                <SiteSettings />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "new-site-setting",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN]}
                redirectTo="/dashboard/main"
              >
                <AddSettingForm />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "add-category",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <AddCategoryForm />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
        {
          path: "update-category/:categoryId",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <RoledProtection
                role={[SUPER_ADMIN, ADMIN, MANAGER]}
                redirectTo="/dashboard/main"
              >
                <AddCategoryForm />
              </RoledProtection>
            </LoggedInProtection>
          ),
        },
      ],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
